import React, { useState, useEffect } from "react"
import { FaArrowCircleUp } from "react-icons/fa"

const ScrollToTop = () => {
  let _isMounted = false

  const [styles, setStyles] = useState()

  useEffect(() => {
    _isMounted = true
    let styles

    const handleScroll = window.addEventListener("scroll", e => {
      document.documentElement.scrollTop > 400
        ? (styles = { opacity: 1, visibility: "visible" })
        : (styles = { opacity: 0, visibility: "hidden" })

      _isMounted && setStyles(styles)
    })

    return () => {
      window.removeEventListener("scroll", handleScroll)
      _isMounted = false
    }
  }, [])

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <div className="scroll-to-top" onClick={handleClick} style={styles}>
      <FaArrowCircleUp className="arrow" />
    </div>
  )
}

export default ScrollToTop
