import React, { useState } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { FaCaretDown } from "react-icons/fa";
import { GoLinkExternal } from "react-icons/go";

const linkSyle = {
  fontWeight: 500,
};

const NavLinks = ({ isSidebarOpen }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isCyfhDropdownOpen, setCyfhDropdownOpen] = useState(false);

  const handleClick = () => {
    const link = document.querySelector("#mobile-dropdown-link");

    if (isDropdownOpen) {
      link.classList.remove("show-dropdown");
    } else if (isDropdownOpen === false) {
      link.classList.add("show-dropdown");
    }

    setDropdownOpen(!isDropdownOpen);
  };

  const handleClickCyfh = () => {
    const link = document.querySelector("#mobile-dropdown-cyfh-link");

    if (isCyfhDropdownOpen) {
      link.classList.remove("show-dropdown");
    } else if (isCyfhDropdownOpen === false) {
      link.classList.add("show-dropdown");
    }

    setCyfhDropdownOpen(!isCyfhDropdownOpen);
  };

  return (
    <>
      <div className="links-wrapper">
        <ul className="links">
          <li className="single-link">
            <Link to="/" activeStyle={linkSyle}>
              Αρχική
            </Link>
          </li>
          <li className="single-link dropdown-link">
            <div
              className="link-wrapper"
              onClick={isSidebarOpen ? handleClick : a => a}
            >
              Εταιρία <FaCaretDown className="dropdown-arrow-icon" />
            </div>
            <div
              className={isDropdownOpen ? "dropdown show-dropdown" : "dropdown"}
              id="mobile-dropdown-link"
            >
              <ul className="dropdown-links">
                <Link to="/board-of-directors" activeStyle={linkSyle}>
                  <li className="dropdown-single-link">Διοικητικό Συμβούλιο</li>
                </Link>
                <Link to="/statute" activeStyle={linkSyle}>
                  <li className="dropdown-single-link">
                    Καταστατικό <GoLinkExternal />
                  </li>
                </Link>
                <Link to="/members-info" activeStyle={linkSyle}>
                  <li className="dropdown-single-link">Πληροφορίες Μελών</li>
                </Link>
              </ul>
            </div>
          </li>
          <li className="single-link">
            <Link
              to="/news-and-events"
              activeStyle={linkSyle}
              partiallyActive={true}
            >
              Νέα &#38; Εκδηλώσεις
            </Link>
          </li>
          <li className="single-link">
            <Link to="/contact">Επικοινωνία</Link>
          </li>
        </ul>
      </div>
      <div className="cta">
        <div className="cyfh">
          <div className="single-link dropdown-link">
            <div
              className="link-wrapper"
              onClick={isSidebarOpen ? handleClickCyfh : a => a}
            >
              <img
                src="//images.ctfassets.net/asl2fe9f2nfi/3KRvxQNH3bpvLSwqVDfLyF/9ec744c7f3a7e24680087e6aaa3e712c/cyfh_logo.png"
                alt="CyFH logo"
              />{" "}
              CyFH <FaCaretDown className="dropdown-arrow-icon" />
            </div>
            <div
              className={
                isCyfhDropdownOpen ? "dropdown show-dropdown" : "dropdown"
              }
              id="mobile-dropdown-cyfh-link"
            >
              <ul className="dropdown-links">
                <Link to="/cyfh-info" activeStyle={linkSyle}>
                  <li className="dropdown-single-link">Τι σημαίνει;</li>
                </Link>
                <Link to="/cyfh-world-day" activeStyle={linkSyle}>
                  <li className="dropdown-single-link">Παγκόσμια Ημέρα FH</li>
                </Link>
                <Link to="/cyfh-registries" activeStyle={linkSyle}>
                  <li className="dropdown-single-link">Μητρώο Καταγραφής</li>
                </Link>
                <Link to="/cyfh-material" activeStyle={linkSyle}>
                  <li className="dropdown-single-link">Ενημερωτικό Yλικό</li>
                </Link>
              </ul>
            </div>
          </div>
        </div>

        <Link to="/application-form" className="transparent-button">
          Αίτηση Εγγραφής
        </Link>
      </div>
    </>
  );
};

export default NavLinks;
