import React from "react"
import NavLinks from "./navLinks"

const Sidebar = ({ isSidebarOpen, setSidebarOpen }) => {
  const sidebarStatus = isSidebarOpen ? "hide-sidebar" : "show-sidebar"
  const overlayStatus = isSidebarOpen ? "show-overlay" : ""

  const handleClick = () => {
    setSidebarOpen(!isSidebarOpen)
  }

  return (
    <>
      <div className={`sidebar ${sidebarStatus}`}>
        <NavLinks isSidebarOpen={isSidebarOpen} />
      </div>
      <div
        className={`sidebar-overlay ${overlayStatus}`}
        onClick={handleClick}
      ></div>
    </>
  )
}

export default Sidebar
