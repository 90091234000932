import React from "react";
import { Link } from "gatsby";

import Logo from "../../images/cas_logo.svg";
import {
  FaFacebookSquare,
  // FaInstagram,
  // FaTwitter,
  // FaYoutube,
} from "react-icons/fa";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="wrapper">
        <div className="logo">
          <Link to="/" className="logo-wrapper">
            <Logo className="logo" alt="Cas Logo" />
          </Link>
        </div>
        <div className="company text-box">
          <h4>Εταιρία</h4>
          <Link to="/board-of-directors">Διοικητικό Συμβούλιο</Link>
          <Link to="/statute">Καταστατικό</Link>
          <Link to="/members-info">Πληροφορίες Μελών</Link>
        </div>
        <div className="other-links text-box">
          <Link to="/application-form">
            <h4>Αίτηση Εγγραφής</h4>
          </Link>
          <Link to="/news-and-events">
            <h4>Νέα & Εκδηλώσεις</h4>
          </Link>
          <Link to="/contact">
            <h4>Επικοινωνία</h4>
          </Link>
        </div>
        <div className="cyfh text-box">
          <h4>CyFH</h4>
          <Link to="/cyfh-info">Τι σημαίνει;</Link>
          <Link to="/cyfh-world-day">Παγκόσμια Ημέρα FH</Link>
          <Link to="/cyfh-registries">Μητρώο Καταγραφής</Link>
          <Link to="/cyfh-material">Ενημερωτικό Υλικό</Link>
        </div>
        <div className="contact">
          <h4>Επικοινωνία</h4>
          <a href="mailto:cas.secretariat@gmail.com">
            cas.secretariat@gmail.com
          </a>
          <p>+357 25 002131</p>
        </div>
        <div className="socials">
          <h4>Ακολουθήστε μας</h4>

          <a
            href="https://www.facebook.com/Cyprus-Atherosclerosis-Society-667250903665933/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookSquare />
          </a>
          {/* <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
            <FaTwitter />
          </a>
          <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
            <FaYoutube />
          </a> */}
        </div>
        <p className="copyright">
          <span>&copy;</span> {year} <span className="separator">|</span>{" "}
          <a href="https://fanismahmalat.com" target="_blank">
            Designed and developed by Fanis Mahmalat
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
