import React, { useEffect, useState } from "react";
import { Link } from "gatsby";

import NavLinks from "./navLinks";
import Sidebar from "./sidebar";

import Logo from "../../images/cas_logo.svg";
import { MdMenu } from "react-icons/md";

const Header = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const header = document.getElementById("cas-nav");
    const scrollCallBack = window.addEventListener("scroll", () => {
      if (window.pageYOffset > 50) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);

  const handleClick = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <header id="cas-header">
      <nav id="cas-nav">
        <div className="wrapper">
          <Link to="/" className="logo">
            <Logo alt="Cyprus Atherosclerosis Society Logo" />
          </Link>
          <NavLinks />

          <div className="sidebar-icon">
            <MdMenu onClick={handleClick} />
          </div>
        </div>
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
      </nav>
    </header>
  );
};

export default Header;
